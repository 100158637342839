/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "La superficie de tierra sobre nuestro planeta es finita, sólo en una parte de esta superficie esta es posible construir. Sólo en un pequeño porcentaje de esta superficie construible existe la posibilidad de establecer una vivienda con lo que consideramos servicios básicos en el primer mundo, como agua corriente, suministro eléctrico y sanitarios. De estas potenciales viviendas únicamente hay una parte que dispone de la conexión a Internet de banda lo suficientemente ancha como para conectarse a un metaverso, es por esto que la vivienda es un recurso escaso y es caro. ¡Carísimo!"), "\n", React.createElement(_components.p, null, "Mientras tanto, en el metaverso el terreno se crea a golpe de teclado. ¿Cuántas hectáreas quieres? ¿10?¿100?¿10.000?¿1.000.000.000? No importa, podrías pasarte corriendo durante días en un metaverso gigante sin cruzarte a nadie y eso a penas influiría en la carga del servidor que lo mantiene. Todo eso podría ser tuyo, pero… ¿Cuánto se está pagando por una parte infinitamente más pequeña? ", React.createElement(_components.a, {
    href: "https://www.elconfidencial.com/tecnologia/novaceno/2022-02-02/metaverso-mercado-inmobiliario_3368601/"
  }, "El precio de terrenos reales"), ", el precio de un recurso escaso que hay en el planeta Tierra por el de un recurso infinito en un metaverso. Metaverso que no olvidemos pertenecerá a alguna empresa, que es posible que cualquier día apague el servidor y desaparezca."), "\n", React.createElement(_components.p, null, "Está bien, no quieres correr kilómetros para encontrar a tu amigo con el que te vas a dar un paseo por tu barrio del metaverso y tal vez no es conveniente un metaverso gigantesco, pero olvidamos una vez más que el metaverso es digital y pueden existir y existirán los portales y el viaje instantaneo."), "\n", React.createElement(_components.p, null, "No tiene sentido. Y cuando digo que no tiene sentido es que és una estafa, ", React.createElement(_components.a, {
    href: "https://www.investing.com/equities/facebook-inc"
  }, "los últimos meses Meta ha caído en bolsa con muchísima fuerza"), ", esto nos debería hacer pensar si el metaverso es únicamente una maniobra de ", React.createElement(_components.em, null, "marketing"), " de ", React.createElement(_components.a, {
    href: "https://litox.entramado.net/2019/07/17/facebook-y-el-monopolio-de-la-moneda/"
  }, "Facebook"), "."), "\n", React.createElement(_components.p, null, "Los metaversos llegarán y te regalarán la entrada y la parcela que tú quieras, será el siguiente redil, claro, que no te estafen y sé consciente de cómo te quieren pastorear."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
